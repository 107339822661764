// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyC7FduCdnfk2ForHRKgWUvaO1gMDlsFgB8",
    authDomain: "slow-clap-f9c5e.firebaseapp.com",
    projectId: "slow-clap-f9c5e",
    storageBucket: "slow-clap-f9c5e.appspot.com",
    messagingSenderId: "847310226627",
    appId: "1:847310226627:web:3fccc6a996e8a5a8330e76",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
