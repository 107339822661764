import "./App.css";
import Countdown from "react-countdown";
import moment from "moment-timezone";
import Lottie from "lottie-react-web";
import animation from "./clapping.json";
import "./firebase";

const options = [
    { date: "2024-10-01T20:00:00.000-06:00", label: "Clap #4" },
    { date: "2025-01-01T20:00:00.000-07:00", label: "Clap #5" },
    { date: "2025-02-16T20:00:00.000-07:00", label: "Clap #6" },
    { date: "2025-03-11T09:00:00.000-06:00", label: "Clap #7" },
    { date: "2025-03-22T15:00:00.000-06:00", label: "Clap #8" },
    { date: "2025-03-28T06:00:00.000-06:00", label: "Clap #9" },
    { date: "2025-03-31T01:30:00.000-06:00", label: "Clap #10" },
    { date: "2025-04-01T11:15:00.000-06:00", label: "Clap #11" },
    { date: "2025-04-02T04:07:30.000-06:00", label: "Clap #12" },
    { date: "2025-04-02T12:33:45.000-06:00", label: "Clap #13" },
    { date: "2025-04-02T16:46:52.500-06:00", label: "Clap #14" },
    { date: "2025-04-02T18:53:26.250-06:00", label: "Clap #15" },
    { date: "2025-04-02T19:56:43.125-06:00", label: "Clap #16" },
    { date: "2025-04-02T20:28:21.562-06:00", label: "Clap #17" },
    { date: "2025-04-02T20:44:10.780-06:00", label: "Clap #18" },
    { date: "2025-04-02T20:52:05.389-06:00", label: "Clap #19" },
    { date: "2025-04-02T20:56:02.693-06:00", label: "Clap #20" },
    { date: "2025-04-02T20:58:01.345-06:00", label: "Clap #21" },
    { date: "2025-04-02T20:59:00.671-06:00", label: "Clap #22" },
    { date: "2025-04-02T20:59:30.334-06:00", label: "Clap #23" },
    { date: "2025-04-02T20:59:45.165-06:00", label: "Clap #24" },
    { date: "2025-04-02T20:59:52.580-06:00", label: "Clap #25" },
    { date: "2025-04-02T20:59:56.287-06:00", label: "Clap #26" },
    { date: "2025-04-02T20:59:58.140-06:00", label: "Clap #27" },
    { date: "2025-04-02T20:59:59.066-06:00", label: "Clap #28" },
    { date: "2025-04-02T20:59:59.529-06:00", label: "Clap #29" },
];

function App() {
    const getMostRecentPastEvent = (options) => {
        const now = moment();

        const pastEvents = options.filter((option) =>
            moment(option.date).isAfter(now)
        );

        if (pastEvents.length === 0) {
            return null;
        }

        let mostRecent = pastEvents[0];

        for (let i = 1; i < pastEvents.length; i++) {
            if (moment(pastEvents[i].date).isBefore(mostRecent.date)) {
                mostRecent = pastEvents[i];
            }
        }

        return mostRecent.date;
    };

    const mostRecentEvent = getMostRecentPastEvent(options);
    const boxStyle = {
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: "20px 40px",
    };
    const renderer = ({ days, hours, minutes, seconds, completed }) => {
        return (
            <div
                style={{
                    display: "flex",
                    borderRadius: "20px",
                    border: "1px solid white",
                }}
            >
                <div style={boxStyle}>
                    <h2>{days}</h2>
                    <div>Days</div>
                </div>
                <div style={{ width: "1px", backgroundColor: "white" }}></div>
                <div style={boxStyle}>
                    <h2>{hours}</h2>
                    <div>Hours</div>
                </div>
                <div style={{ width: "1px", backgroundColor: "white" }}></div>
                <div style={boxStyle}>
                    <h2>{minutes}</h2>
                    <div>Minutes</div>
                </div>
                <div style={{ width: "1px", backgroundColor: "white" }}></div>
                <div style={boxStyle}>
                    <h2>{seconds}</h2>
                    <div>Seconds</div>
                </div>
            </div>
        );
    };

    return (
        <div className="App">
            <header className="App-header">
                <Lottie
                    className="App-logo"
                    style={{
                        height: "15em",
                        width: "15em",
                        position: "relative",
                        left: "-42px",
                    }}
                    speed={0.4}
                    options={{
                        animationData: animation,
                    }}
                />
                <div style={{ marginTop: "5px", marginBottom: "30px" }}>
                    Next Clap on {moment(mostRecentEvent).format("LLLL")}
                </div>
                <Countdown date={mostRecentEvent} renderer={renderer} />
            </header>
        </div>
    );
}

export default App;
